.sectipop {
    width: 600px;
    height: auto;
    font-family: "Caveat Brush", cursive;
    opacity: 1;
    font-size: 1.4em;
    justify-content: center;
    align-items: center;
}

.poff {
    width: 100%;
    padding-top: 70px;
    opacity: 1;
    font-size: 1.4em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-right: 50px;
    @media (max-width: 600px) {

        padding-top: 30px;
        padding-right: 25px;
    }
}

.sectipop2 {
    width: 600px;
    height: auto;
    align-items: center;
    opacity: 1;
    justify-content: center;
}

.secti {

    height: 100vh;
    font-family: "Caveat Brush", cursive;
    opacity: 1;
}

.sectimint {
    align-items: center;
    justify-content: center;
    opacity: 1;
}

.visible {
    color: gray;
    font-family: "Caveat Brush", cursive;
    font-size: 1em;
    opacity: 1;
}

.disp {
    padding: 20px;
    padding-bottom: 0px;
    font-family: "Caveat Brush", cursive;
    font-size: 1.4em;
    text-align: center;
    align-items: center;
}

.disp2 {
    margin-top: 20px;
    padding: 20px;
    text-align: center;
    align-items: center;
}

.navi {
    font-family: "Caveat Brush", cursive;
    font-size: 1.4em;
}

.pick {
    opacity: 1;
}

.nopick {
    opacity: 0.5;
}

.backg {
    transition: 1s all;
    opacity: 1;
}

.backtest {
    width: 100vw;
    height: auto;
    background-image: url("./assets/map.png");
}

.hide {
    opacity: 0;
    pointer-events: none;
    z-index: -1;
    transform: scale(0.5);
}

.hiddenav {
    display: none;
}

.boobs {
    transition: 0.8s all;
    display: none;
}

.backb {
    transition: 1.3s all;
    display: flex;
}

.progress-bar {
    height: 40px;
    width: 220px;
    font-size: 10px;
    font-size: x-large;
    text-align: center;
    background-color: #3a9042;
}

.progress {
    width: 200px;
    height: 30px;
    background-color: #bbbbbb;
}

.progress span {
    color: #ffffff;
    position: absolute;
    display: block;
    width: 100%;
}

.dead {
    opacity: 0.4;
}

.round-corners {
    border-radius: 10px;
}

.poffimg {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 230px;
    }

    @media (max-width: 600px) {
        img {
            width: 130px;
        }
    }
}

.poffbutt {

    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 150px;
    }

    @media (max-width: 600px) {

        img {
            width: 100px;
        }
    }
}

.pofftext {
    font-family: "Caveat Brush", cursive;
    font-size: 1.5em;
    display: flex;
    align-items: center;
    align-items: center;

    @media (max-width: 600px) {
        font-size: 0.7em;
    }
}
.pofftext p {
    white-space: pre-wrap; /* Preserve whitespace and wrap text */
    text-align: center;
    word-wrap: break-word; /* Break long words to fit the container */
}

.modal-body {
    background-image: url("./assets/poff-modal.png");
    background-repeat: no-repeat;
    background-size: 750px;
    width: 750px;
    height: 750px;
    margin-top: 20px;


    @media (max-width: 600px) {

        margin-top: 50px;
        width: 100vw;
        background-size: 95vw;

    }
}


.knappcolumn {
    margin-top: 10px;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    display: flex;
}

.knappcolumn2 {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
}

.bigbox {
    font-family: "Caveat Brush", cursive;
    margin-top: 8px;
    margin-right: 40px;
    font-size: 2em;
    color: #000000;
    display: flex;
    flex-direction: column;
    opacity: 1;
    justify-content: center;
    align-items: center;
}
